import React, { Component } from "react"
import { Link } from "gatsby"

// TO USE: takes in two props: 1) buttonPage - page name to link to and 2) buttonText - text inside button
export class RedButton extends Component {
    render() {
        return (
            <div>
                <Link to={this.props.buttonPage}>
                    <button className="m-5 relative h-10 py-2 px-5 mx-auto transition-colors rounded-lg focus:shadow-outline bg-gradient-to-r from-dhredfrom to-dhredto text-white hover:shadow-lg hover:shadow-dhred font-semibold">
                        <span>{this.props.buttonText}</span>
                    </button>
                </Link>
            </div >
        )
    }
}