import * as React from "react"
import Seo from "../components/seo"
import Layout from "../components/layout"
import { RedButton } from "../components/button-red"

const NotFoundPage = (props) => {
  return (
    <Layout>
      <Seo
        title={"404: Page Not Found - Dahlia Homes"}
        location={props.location.pathname}
      />
      <div className="text-black pt-72 text-center pb-96 bg-dhgray px-10">
        <h1 className="text-3xl font-bold pb-5">404: Page Not Found</h1>
        <p className="text-3xl max-w-5xl mx-auto font-medium leading-relaxed">
          Check the web address and try again or return to the Dahlia Homes Home Page
        </p>
        <RedButton buttonText="Home Page" buttonPage="/" />
      </div>
    </Layout>
  )
}
export default NotFoundPage
